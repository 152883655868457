import React from 'react';
import SectionTitle from '../Common/SectionTitle';

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
            <SectionTitle
                sectionClass="sec-title mb-44"
                subtitleClass="sub-text new-text white-color"
                subtitle="Let's Talk"
                titleClass="title white-color"
                title="Schedule an appointment with us and harness the power of AI for your Business !"
            />
        </div>
    );

}

export default ContactInfo;
