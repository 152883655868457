import React from 'react';
import About from '../home/AboutSection';
import Service from '../home/ServiceSection';
import CallUs from '../home/CallUsSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerDefault from '../../components/Banner/BannerDefault';
import OnepageContactPart from '../../components/Contact/OnepageContact';
import Testimonial from '../../components/Testimonial';

const HomeOnepageMain = () => {

	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerDefault />
			{/* banner-start */}

			{/* about-area-start */}
			<About />
			{/* about-area-end */}

			{/* services-area-start */}
			<Service />
			{/* services-area-end */}

			{/* call us section start */}
			<CallUs />
			{/* call us section end */}

			{/* process-area-start */}


			
			
			
			{/* Contact-area-start */}
			<OnepageContactPart 
			// eslint-disable-next-line
				contactSectionClass="rs-contact gray-bg pt-120 md-pt-80"
			/>
			{/* Contact-area-end */}


            {/* Testimonials-area-start */}
            <Testimonial />
            {/* Testimonials-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}


		</React.Fragment>
	);
}

export default HomeOnepageMain;
