import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/img/logo/logo.png';

const TopHeader = () => {

    return (
        <div className="topbar-area hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2">
                        <div className="logo-part">
                            <Link to="/"><img src={Logo} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="col-lg-10 text-right">
                        <ul className="rs-contact-info">
            
                         

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;
