import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import serviceIcon1 from '../../assets/img/service/icons/1.png';
import serviceIcon2 from '../../assets/img/service/icons/2.png';
import serviceIcon3 from '../../assets/img/service/icons/3.png';
import serviceIcon4 from '../../assets/img/service/icons/4.png';
import serviceIcon5 from '../../assets/img/service/icons/5.png';
import serviceIcon6 from '../../assets/img/service/icons/6.png';

const Service = () => {

    return (
        <div id="rs-service" className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text"
                    subtitle="Services"
                    titleClass="title"
                    title="Schedule an appointment and discover which solution aligns best with your objectives."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            serviceURL="web-development"
                            Title="Tailored artificial intelligence solutions" 
                            Text="Datawises crafts tailor-made machine learning and deep learning solutions. These include for example: 'Demand Forecasting' models for accurate planning and budgeting, 'Risk Management Tools' using anomaly detection for preemptive measures, and 'Sentiment Analysis' systems for real-time market and customer mood tracking." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            serviceURL="analytic-solutions"
                            Title="Analytic Solutions" 
                            Text="We delve into your data, analyze it, and translate it into actionable insights that fuel growth and enhance decision-making. By identifying trends and patterns, we help you understand your market and customers better, optimize your operations, and predict future outcomes." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            serviceURL="clould-devOps"
                            Title="Effortless CRM integration" 
                            Text="Datawises ensures effortless integration of our solutions into your existing CRM or ERP systems. Our tailored applications, be it chatbots or machine learning tools, are designed to enhance operational efficiency without disrupting workflows. We prioritize seamless integration, promoting smooth transitions and maximizing business success." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 sm-mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon5}
                            serviceURL="product-design"
                            Title="Effortless Deployment and Installation" 
                            Text="Datawises ensures seamless software deployment and easy installation for our clients. Our clear guidelines and swift processes enable rapid integration into existing business infrastructures. We focus on minimizing delays and maximizing your operational efficiency." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon6}
                            serviceURL="data-center"
                            Title="Dedicated customer support" 
                            Text="At Datawises, we prioritize your peace of mind. Our dedicated customer support team is always ready to step in if anything unexpected occurs. With round-the-clock assistance, we ensure your operations remain uninterrupted and your queries get timely solutions. Your success is our ultimate guarantee." 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
