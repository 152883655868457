import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../Common/SectionTitle';
import SingleTestimonial from './SingleTestimonial';


import quote from '../../assets/img/testimonial/quote.png';
import author1 from '../../assets/img/testimonial/mckinseY.png';
import author2 from '../../assets/img/testimonial/SYT.png';
import author3 from '../../assets/img/testimonial/Tyrscale.png';

const Testimonial = () => {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        center: true,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                stagePadding: 0,

            },
            992: {
                items: 2,
                stagePadding: 0,

            },
            1200: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div className="rs-testimonial main-home gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-45"
                        subtitleClass="sub-text"
                        subtitle="Testimonial"
                        titleClass="title testi-title"
                        title=" What say our customers"
                        effectClass="heading-line"
                    />
                    <OwlCarousel options={options} >
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="McKinsey" 
                            Designation="" 
                            Description="Datawise provided us with a forward-thinking perspective thanks to their research and implementation strategy of innovative AI business Analytics tool that rely on quantum computing. They also provided a strategic guide on how to harness the power of AI with LLM's in legal frameworks, enhancing our operational efficiency and service offerings."

                        />
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author2}
                            Title="Search Your Team (Ex French SportsTryouts)" 
                            Designation="" 
                            Description="Datawise was instrumental in leading our technical team by leveraging advanced machine learning algorithms alongside a solid database for our web application. Datawise's technical acumen in machine learning not only revolutionized our matching system but also showcased exemplary management skills, enabling us to cover more than 28 online newspaper and get credits from the French National Aassembly Vice-President."
                        />
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author3}
                            Title="Tyrscale" 
                            Designation="" 
                            Description="At Tyrscale, Datawise showcased an exceptional level of expertise in business analytics for our client, Loopies. Through meticulous data collection and preprocessing, they crafted insightful visualizations that illuminated key market trends. The analytical prowess was pivotal in shaping Loopies' Go-To-Market strategy, offering clarity and direction that was instrumental in defining their path forward in a competitive landscape."

                        />
                       
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Testimonial