import React from 'react';

const ContactForm = (props) => {
    const { submitBtnClass } = props;
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission

        // Collect form data
        const formData = {
            name: document.getElementById('name').value,
            email: document.getElementById('email').value,
            phone: document.getElementById('phone').value,
            website: document.getElementById('website').value,
            message: document.getElementById('message').value,
        };

        // Send form data to serverless function
        try {
            const response = await fetch('/.netlify/functions/send-email', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Check if the response is successful
            if (response.ok) {
                // Clear the form
                document.getElementById('contact-form').reset();
                // Display a popup message
                alert('Email sent!');
            } else {
                // If response is not OK, handle errors
                const errorText = await response.text(); // Get the text of the error response
                console.error('Email sending error:', errorText);
                alert('Failed to send email. Please try again later.');
            }
        } catch (error) {
            // Handle network errors
            console.error('Network error:', error.message);
            alert('Network error. Please try again later.');
        }
    };

    return (
        <form id="contact-form" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="name" placeholder="Name" required />
                </div>
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="email" name="email" placeholder="E-Mail" required />
                </div>
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required />
                </div>
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="website" name="website" placeholder="Your Website" required />
                </div>
                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" placeholder="Your message Here" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="submit">Submit Now</button>
            </div>
        </form>
    );
}

export default ContactForm;
