import React from 'react';
import OnepageHeader from '../../components/Layout/Header/OnepageHeader';
import HomeOnepageMain from './HomeOnepageMain';

const HomeOnePage = () => {
    return (
        <React.Fragment>
            <OnepageHeader />
            <HomeOnepageMain />
        </React.Fragment>
    );
}

export default HomeOnePage;
