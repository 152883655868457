import React from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

// Import the OnePage1 component
import HomeOnePage from '../pages/onepage-1';

const App = () => {
    return (
        <div className='App'>
            <Router>
                <Switch>
                    {/* Set up the route for the root path */}
                    <Route exact path="/" component={HomeOnePage} />

                    {/* Optionally, redirect all other routes to the root path */}
                    <Redirect to="/" />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
