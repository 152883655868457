import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import MyVideo from '../../assets/videos/video.mp4'; 



const BannerDefault = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div className="rs-banner style1">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='GLlZRKwKSrA' onClose={() => { openModal(); }} />
                <div className="banner-height pt-100 pb-100 md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 pt-40 pr-140 md-pt-0 md-mb-60 md-pr-14">
                                <div className="banner-content">
                                    <h2 className="bnr-title" data-animation="fadeInLeft" data-delay=".3s">Your trusted consulting agency for tailored<br />software solutions.</h2>
                                    <p className="bnr-desc" data-animation="fadeInLeft" data-delay=".5s">Discover who we are in the pop up video below and begin your journey with us!</p>
                                    <div className="banner-video">
                                        <a className="popup-video" onClick={openModal}><i className="fa fa-play"></i> Play</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 pl-70 lg-pl-30 md-pl-14">
                                {/* Place your video here */}
                                <video width="100%" controls>
                                    <source src={MyVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}
        </React.Fragment>
    );
}

export default BannerDefault;
